<template>
  <div class="chatSetting">
    <pageTitle pageTitle="聊天设置"></pageTitle>
    <div class="chatSettingContainer">
      <vh-tabs v-model="tabType">
        <vh-tab-pane label="聊天严禁词" name="forbiddenWord">
          <ForbiddenWord v-if="tabType == 'forbiddenWord'"></ForbiddenWord>
        </vh-tab-pane>
        <vh-tab-pane name="kickOut">
          <span slot="label">
            全局踢出
            <vh-tooltip effect="dark" placement="right" v-tooltipMove>
              <div slot="content">
                <div class="text">
                  <p>1.全局踢出观众会被限制无法进入直播间</p>
                  <p>2.当前设置对所有直播活动都生效，单场活动的禁言踢出在主播端设置</p>
                </div>
              </div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
          <KickOut v-if="tabType == 'kickOut'"></KickOut>
        </vh-tab-pane>
        <vh-tab-pane label="消息推送" name="pushMsg" v-if="chat_msg_push_third == 1">
          <PushMsg v-if="tabType == 'pushMsg'"></PushMsg>
        </vh-tab-pane>
        <vh-tab-pane name="hidechat">
          <span slot="label">
            聊天隐藏与禁言
            <vh-tooltip effect="dark" placement="right" v-tooltipMove>
              <div slot="content">
                <div class="text">
                  <p>1.隐藏聊天后直播间观众将无法看到聊天模块，主持人等其他角色仍然可见</p>
                  <p>2.全局禁言涉及角色包括主持人、嘉宾、助理及观众</p>
                  <p>3.全局禁言范围涉及聊天、问答及私聊</p>
                </div>
              </div>
              <i class="iconfont-v3 saasicon_help_m"></i>
            </vh-tooltip>
          </span>
          <section class="chat_hide_banned">
            <vh-form v-if="tabType == 'hidechat'" class="hidechat">
              <vh-form-item label="隐藏聊天">
                <p class="switch__box">
                  <vh-switch
                    v-model="globalChatConfig.global_chat_hide"
                    active-color="#ff4949"
                    inactive-color="#ccc"
                    @change="saveGlobalConfig"
                    active-value="1"
                    inactive-value="0"
                    :active-text="hideChatStr"
                  ></vh-switch>
                </p>
              </vh-form-item>
              <vh-form-item label="全局禁言">
                <p class="switch__box">
                  <vh-switch
                    v-model="globalChatConfig.global_banned"
                    active-color="#ff4949"
                    inactive-color="#ccc"
                    active-value="1"
                    inactive-value="0"
                    @change="saveGlobalConfig"
                    :active-text="bannedAllStr"
                  ></vh-switch>
                </p>
              </vh-form-item>
            </vh-form>
            <div class="preview">
              <!-- 模拟开关 -->
              <div class="zdy--switch">
                <span
                  :class="switchType === 'app' ? 'active' : ''"
                  @click.prevent.stop="switchType = 'app'"
                >
                  手机预览
                </span>
                <span
                  :class="switchType === 'pc' ? 'active' : ''"
                  @click.prevent.stop="switchType = 'pc'"
                >
                  PC预览
                </span>
              </div>
              <!--PC预览,begin-->
              <div v-show="switchType === 'pc'" class="pc_preivew">
                <span class="chat-span" v-if="globalChatConfig.global_chat_hide != 1">
                  {{ globalChatConfig.global_banned == 1 ? `您已被禁言` : `说点什么` }}
                </span>
                <img
                  :src="
                    globalChatConfig.global_chat_hide == 1
                      ? require('../../common/images/plan-function/pc-hidechat.png')
                      : require('../../common/images/plan-function/pc-dafault2.png')
                  "
                  alt=""
                />
              </div>
              <!--手机预览,begin-->
              <div v-show="switchType === 'app'" class="app_preivew">
                <span class="chat-span" v-if="globalChatConfig.global_chat_hide != 1">
                  {{ globalChatConfig.global_banned == 1 ? `您已被禁言` : `说点什么` }}
                </span>
                <img
                  :src="
                    globalChatConfig.global_chat_hide == 1
                      ? require('../../common/images/plan-function/phone-chathide.png')
                      : require('../../common/images/plan-function/phone-default.png')
                  "
                  alt=""
                />
              </div>
            </div>
          </section>
        </vh-tab-pane>
      </vh-tabs>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import ForbiddenWord from './ForbiddenWord/Index.vue';
  import KickOut from './kickOut.vue';
  import PushMsg from './pushMsg.vue';

  export default {
    name: 'chatMgr',
    components: {
      PageTitle,
      ForbiddenWord,
      KickOut,
      PushMsg
    },
    data() {
      return {
        tabType: 'forbiddenWord',
        chat_msg_push_third: 0,
        globalChatConfig: {
          global_chat_hide: '0',
          global_banned: '0'
        },
        switchType: 'app'
      };
    },
    computed: {
      hideChatStr() {
        return `${
          this.globalChatConfig.global_chat_hide == '1' ? '已开启' : '开启后'
        }，账号下所有直播间都隐藏聊天模块`;
      },
      bannedAllStr() {
        return `${
          this.globalChatConfig.global_banned == '1' ? '已开启' : '开启后'
        }，账号下所有直播间都禁止发言`;
      }
    },
    mounted() {
      this.getGlobalConfig();
    },
    methods: {
      getGlobalConfig() {
        this.$fetch('getGeneralConfig', {
          type: 'global_chat_banned'
        }).then(res => {
          if (res.code == 200) {
            res.data.content && (this.globalChatConfig = JSON.parse(res.data.content));
          }
        });
      },
      saveGlobalConfig() {
        this.$fetch('saveGeneralConfig', {
          type: 'global_chat_banned',
          content: JSON.stringify(this.globalChatConfig)
        })
          .then(res => {
            if (res.code == 200) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(err => {
            this.$message.error(err.msg);
          });
      }
    },
    created() {
      const permission = JSON.parse(localStorage.getItem('SAAS_VS_PES'));
      this.chat_msg_push_third = permission['chat_msg_push_third'];
    },
    beforeDestroy() {}
  };
</script>

<style lang="less" scoped>
  .chatSettingContainer {
    background-color: #fff;
  }
  .chat_hide_banned {
    display: flex;
    .hidechat {
      min-height: 600px;
      padding: 30px;
      flex: 1;
    }
    .preview {
      width: 400px;
      img {
        width: 400px;
        margin: 0 auto;
      }
      .pc_preivew {
        position: relative;
        .chat-span {
          position: absolute;
          color: #999;
          font-size: 12px;
          left: 286px;
          bottom: 31px;
          transform-origin: top left;
          transform: scale(0.5);
        }
      }
      .app_preivew {
        position: relative;
        img {
          width: 300px;
        }
        .chat-span {
          position: absolute;
          bottom: 46px;
          font-size: 10px;
          color: #999;
          left: 58px;
        }
      }
    }
  }
</style>
<style lang="less">
  .chatSettingContainer {
    .vh-tabs__content {
      padding: 0 20px 20px;
    }
  }
</style>

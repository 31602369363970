<template>
  <div class="pushMsg">
    <vh-form :model="pushMsgForm" ref="pushMsgForm" :rules="pushMsgFormRules" label-width="88px">
      <vh-form-item label="消息推送" prop="switch">
        <div class="switchBox">
          <vh-switch
            v-model="pushMsgForm.switch"
            :active-value="1"
            :inactive-value="0"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            :active-text="
              pushMsgForm.switch
                ? '已开启，直播间中新增聊天问答或主持人推送签到、公告等互动内容时进行通知'
                : '开启后，直播间中新增聊天问答或主持人推送签到、公告等互动内容时进行通知'
            "
          ></vh-switch>
          <!-- <div class="useDes" @click="openUseDes">使用说明</div> -->
        </div>
      </vh-form-item>
      <vh-form-item label="签名key" prop="signKey">
        <vh-input
          v-model="pushMsgForm.signKey"
          autocomplete="off"
          type="text"
          :maxlength="32"
          :show-word-limit="true"
          placeholder="请输入签名规则"
          :disabled="pushMsgForm.switch == 0"
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="回调地址" prop="resUrl">
        <vh-input
          v-model="pushMsgForm.resUrl"
          autocomplete="off"
          type="text"
          :maxlength="255"
          :show-word-limit="true"
          placeholder="请输入https或者http开头的完整url"
          :disabled="pushMsgForm.switch == 0"
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="消息格式" prop="msgType">
        <vh-radio-group v-model="pushMsgForm.msgType" :disabled="pushMsgForm.switch == 0">
          <vh-radio v-for="item of msgTypes" :label="item" :key="item"></vh-radio>
        </vh-radio-group>
      </vh-form-item>
      <vh-form-item label="消息类型" prop="msgChecked">
        <vh-checkbox-group v-model="pushMsgForm.msgChecked" :disabled="pushMsgForm.switch == 0">
          <vh-checkbox v-for="item of msgGroup" :key="item.msg_type" :label="item.msg_type">
            {{ item.msg_type_desc }}
          </vh-checkbox>
        </vh-checkbox-group>
      </vh-form-item>
    </vh-form>
    <div class="btn">
      <vh-button round type="primary" size="medium" @click="handleOk">保存</vh-button>
    </div>
  </div>
</template>

<script>
  import regRule from '@/utils/reg-rule';
  import { sessionOrLocal } from '@/utils/utils';
  const msgTypes = ['application/x-www-form-urlencoded', 'application/json'];
  export default {
    name: 'pushMsg',
    components: {},
    data() {
      const validUrlValue = (rule, value, callback) => {
        if (value && regRule.url.test(value)) {
          callback();
        } else {
          callback(new Error('请输入https或者http开头的完整url'));
        }
      };
      const validMsgValue = (rule, value, callback) => {
        if (value.length == 0) {
          callback(new Error('请选择至少一个消息类型'));
        } else {
          callback();
        }
      };
      return {
        msgTypes,
        pushMsgForm: {
          switch: 0,
          signKey: '',
          resUrl: '',
          msgType: 'application/x-www-form-urlencoded',
          msgChecked: []
        },
        pushMsgFormRules: {
          signKey: [{ required: true, message: '请输入签名key', trigger: 'blur' }],
          resUrl: [{ required: true, validator: validUrlValue, trigger: 'blur' }],
          msgChecked: [{ required: true, validator: validMsgValue, trigger: 'blur' }]
        },
        userId: '',
        msgGroup: []
      };
    },
    computed: {},
    mounted() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.getAllMsgs();
      this.getPushConfig();
    },
    methods: {
      getAllMsgs(type) {
        this.$fetch('getAllMsgs', {}).then(res => {
          if (res.code == 200) {
            this.msgGroup = res.data.list || [];
          }
        });
      },
      getPushConfig(type) {
        this.$fetch('getPushConfig', {}).then(res => {
          if (res.code == 200) {
            this.pushMsgForm.switch = res.data.push_switch || 0;
            this.pushMsgForm.signKey = res.data.secret_key || '';
            this.pushMsgForm.resUrl = res.data.callback_url || '';
            this.pushMsgForm.msgType = res.data.content_type || 'application/x-www-form-urlencoded';
            this.pushMsgForm.msgChecked = res.data.msg_types
              ? res.data.msg_types.split(',')
              : this.msgGroup.map(e => e.msg_type);
          }
        });
      },
      handleOk() {
        let params = {
          push_switch: this.pushMsgForm.switch
        };
        if (this.pushMsgForm.switch == 0) {
          this.$refs.pushMsgForm.clearValidate();
          this.save(params);
        } else {
          this.$refs.pushMsgForm.validate(valid => {
            if (valid) {
              params.secret_key = this.pushMsgForm.signKey;
              params.callback_url = this.pushMsgForm.resUrl;
              params.content_type = this.pushMsgForm.msgType;
              params.msg_types = this.pushMsgForm.msgChecked.join(',');
              this.save(params);
            }
          });
        }
      },
      save(params) {
        this.$fetch('savePushConfig', {
          ...params
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage.success('保存成功');
            }
          })
          .catch(error => {
            this.$vhMessage.error(error.msg);
          });
      },
      openUseDes() {
        window.open('https://saas-doc.vhall.com/opendocs/show/1612');
      }
    },
    created() {},
    beforeDestroy() {}
  };
</script>

<style lang="less">
  .pushMsg {
    min-height: 400px;
    .useDes {
      float: right;
      color: #3562fa;
      cursor: pointer;
    }
    .vh-input {
      max-width: 600px;
    }
    .vh-input--limit .vh-input__inner,
    .vh-input--limit.vh-input--medium .vh-input__inner,
    .vh-input--limit.vh-input--mini .vh-input__inner,
    .vh-input--limit.vh-input--small .vh-input__inner {
      padding-right: 60px;
    }
    .btn {
      margin-left: 88px;
      .vh-button {
        min-width: 80px;
      }
    }
  }
</style>

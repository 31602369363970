<template>
  <!-- 设置严禁词弹窗 -->
  <VhallDialog width="720px" title="设置严禁词" :visible="visible" @close="handleClose">
    <vh-form :model="settingForm" ref="settingForm" label-width="120px">
      <vh-form-item label="" prop="intercept_way">
        <div slot="label">
          <span style="margin-right: 2px">拦截方式</span>
          <vh-tooltip>
            <div slot="content">
              1.严禁词仅自己可见：当发言内容带有设置的严禁词后，聊天消息仅发言人自己可见；
              <br />
              2.当发言内容带有设置的严禁词后，聊天消息中的严禁词以*的形式发出
            </div>
            <i class="iconfont-v3 saasicon_help_m"></i>
          </vh-tooltip>
        </div>
        <vh-radio v-model="settingForm.intercept_way" :label="0">严禁词仅自己可见</vh-radio>
        <vh-radio v-model="settingForm.intercept_way" :label="1">严禁词以*的形式发出</vh-radio>
      </vh-form-item>
      <vh-form-item label="过滤手机号" prop="filter_mobile_switch">
        <vh-switch
          v-model="settingForm.filter_mobile_switch"
          :active-value="filterMobileSwitchCfg.activeValue"
          :inactive-value="filterMobileSwitchCfg.inactiveValue"
          active-color="#FB3A32"
          inactive-color="#CECECE"
        ></vh-switch>
        <span class="leve3_title title--999" style="vertical-align: text-top">
          {{
            settingForm.filter_mobile_switch != filterMobileSwitchCfg.activeValue
              ? filterMobileSwitchCfg.inactiveTip
              : filterMobileSwitchCfg.activeTip
          }}
        </span>
      </vh-form-item>
      <vh-form-item label="过滤QQ号" prop="filter_qq_switch">
        <vh-switch
          v-model="settingForm.filter_qq_switch"
          :active-value="filterQqSwitchCfg.activeValue"
          :inactive-value="filterQqSwitchCfg.inactiveValue"
          active-color="#FB3A32"
          inactive-color="#CECECE"
        ></vh-switch>
        <span class="leve3_title title--999" style="vertical-align: text-top">
          {{
            settingForm.filter_qq_switch != filterQqSwitchCfg.activeValue
              ? filterQqSwitchCfg.inactiveTip
              : filterQqSwitchCfg.activeTip
          }}
        </span>
      </vh-form-item>
      <vh-form-item label="对特殊角色生效" prop="special_role_switch">
        <vh-switch
          v-model="settingForm.special_role_switch"
          :active-value="specialRoleSwitchCfg.activeValue"
          :inactive-value="specialRoleSwitchCfg.inactiveValue"
          active-color="#FB3A32"
          inactive-color="#CECECE"
        ></vh-switch>
        <span class="leve3_title title--999" style="vertical-align: text-top">
          {{
            settingForm.special_role_switch != specialRoleSwitchCfg.activeValue
              ? specialRoleSwitchCfg.inactiveTip
              : specialRoleSwitchCfg.activeTip
          }}
        </span>
      </vh-form-item>
    </vh-form>
    <div slot="footer" class="dialog-footer">
      <vh-button size="medium" plain round @click="handleClose">取 消</vh-button>
      <vh-button
        type="primary"
        :disabled="!isInited"
        v-preventReClick
        size="medium"
        round
        @click="handleSubmit"
      >
        确 定
      </vh-button>
    </div>
  </VhallDialog>
</template>
<script>
  import to from 'await-to-js';
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      visible(newVal) {
        if (newVal) {
          this.isInited = false;
          this.getSettingInfo();
        }
      }
    },
    data() {
      return {
        isInited: false,
        settingForm: {
          intercept_way: 0, // 拦截方式，0-严禁词仅自己可见 1-严禁词以*的形式发出
          filter_mobile_switch: 0, // 过滤手机号，0-关 1-开，仅针对11位手机号，默认0
          filter_qq_switch: 0, //过滤QQ号，0-关 1-开，默认0
          special_role_switch: 1 // 对特殊角色生效，0-关 1-开，默认1
        },
        filterMobileSwitchCfg: {
          activeValue: 1,
          activeTip: '已开启，针对11位的手机号，中间4位数字替换为*的字符发出',
          inactiveValue: 0,
          inactiveTip: '开启后，针对11位的手机号，中间4位数字替换为*的字符发出'
        },
        filterQqSwitchCfg: {
          activeValue: 1,
          activeTip: '已开启，针对5-10的QQ号数字，整体被替换为*的字符发出',
          inactiveValue: 0,
          inactiveTip: '开启后，针对5-10的QQ号数字，整体被替换为*的字符发出'
        },
        specialRoleSwitchCfg: {
          activeValue: 1,
          activeTip: '已开启，主持人、嘉宾、助理的发言，也会经过严禁词、人工聊天审核的过滤',
          inactiveValue: 0,
          inactiveTip: '开启后，主持人、嘉宾、助理的发言，也会经过严禁词、人工聊天审核的过滤'
        }
      };
    },
    methods: {
      handleClose() {
        this.$emit('update:visible', false);
      },
      async getSettingInfo() {
        let [err, res] = await to(this.$fetch('getKeywordSetting', {}));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return false;
        }
        if (res?.data) {
          this.settingForm = {
            ...res.data
          };
          this.isInited = true;
        }
      },
      async handleSubmit() {
        const params = {
          ...this.settingForm
        };
        const [err, res] = await to(this.$fetch('saveKeywordSetting', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return false;
        }
        this.$message.success('设置成功');
        this.handleClose();
      }
    }
  };
</script>

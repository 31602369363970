import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=646f7617&scoped=true&"
import script from "./chat.vue?vue&type=script&lang=js&"
export * from "./chat.vue?vue&type=script&lang=js&"
import style0 from "./chat.vue?vue&type=style&index=0&id=646f7617&prod&lang=less&scoped=true&"
import style1 from "./chat.vue?vue&type=style&index=1&id=646f7617&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646f7617",
  null
  
)

export default component.exports
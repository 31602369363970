<template>
  <div class="kickOut">
    <null-page :nullType="'nullData'" v-if="!total && !isSearch" text="您还没有设置全局踢出观众">
      <vh-button round type="primary" size="medium" @click="add">新增全局踢出观众</vh-button>
    </null-page>
    <div class="filterGroup" v-else>
      <div class="left">
        <vh-button round type="primary" size="medium" @click="add">新增全局踢出观众</vh-button>
        <vh-button
          round
          size="medium"
          type="info"
          plain
          :disabled="selectIds.length <= 0"
          @click="onDelete('')"
        >
          批量移出
        </vh-button>
      </div>
      <div class="right">
        <vh-input
          round
          size="medium"
          @keyup.enter.native="getList('clearPage')"
          clearable
          @clear="getList('clearPage')"
          style="width: 220px"
          v-model="searchParams.searchName"
          autocomplete="off"
          placeholder="搜索观众"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="getList('clearPage')"></i>
        </vh-input>
      </div>
    </div>
    <vh-table
      @select-all="onSelect"
      empty-text=""
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      ref="multipleTable"
      :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
      v-if="total > 0 || isSearch"
      @selection-change="onSelect"
    >
      <span slot="empty"></span>
      <vh-table-column type="selection" width="55" align="left" />
      <vh-table-column label="观众标识" prop="kick_value" show-overflow-tooltip></vh-table-column>
      <vh-table-column label="标识类型">
        <template slot-scope="scope">
          {{ scope.row.kick_type | typeFilter }}
        </template>
      </vh-table-column>
      <vh-table-column label="最后操作" prop="updated_at" width="200"></vh-table-column>
      <vh-table-column label="操作" align="left" width="150">
        <template slot-scope="scope">
          <vh-button v-preventReClick type="text" @click="onDelete(scope.row)">移出</vh-button>
          <vh-button
            v-preventReClick
            type="text"
            v-if="scope.row.operator_role == 0"
            @click="onEdit(scope.row)"
          >
            修改
          </vh-button>
        </template>
      </vh-table-column>
    </vh-table>
    <div v-show="total > 10">
      <SPagination
        :total="total"
        :page-size="searchParams.page_size"
        :currentPage="searchParams.page"
        @current-change="currentChange"
        @size-change="sizeChange"
      ></SPagination>
    </div>

    <null-page text="未搜索到相关内容" nullType="search" v-if="total === 0 && isSearch"></null-page>
    <vh-dialog
      :title="curBox.id ? '修改全局踢出观众' : '新增全局踢出观众'"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="450px"
    >
      <vh-form label-width="100px" :model="curBox" ref="kickOutForm" :rules="rules">
        <vh-form-item label="标识类型" prop="kick_type">
          <vh-select v-model="curBox.kick_type" placeholder="请选择">
            <template v-for="(item, index) of TypeMap">
              <vh-option
                v-if="index != 4"
                :key="index"
                :label="item"
                :value="parseInt(index + '')"
              ></vh-option>
            </template>
          </vh-select>
        </vh-form-item>
        <vh-form-item :label="TypeMap[curBox.kick_type]" prop="kick_value">
          <vh-input
            v-model="curBox.kick_value"
            :maxlength="100"
            show-word-limit
            :placeholder="'请输入' + TypeMap[curBox.kick_type]"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
      </vh-form>

      <span slot="footer" class="dialog-footer">
        <vh-button size="medium" type="info" plain round @click="handleClose">取消</vh-button>
        <vh-button size="medium" type="primary" round @click="handleOk">确定</vh-button>
      </span>
    </vh-dialog>
  </div>
</template>

<script>
  import NullPage from '../PlatformModule/Error/nullPage.vue';
  import regRule from '@/utils/reg-rule';
  export const TypeMap = {
    1: '手机号',
    2: '邮箱',
    3: '三方用户ID',
    4: '游客'
  };

  export default {
    name: 'kickOut',
    components: {
      NullPage
    },
    data() {
      const validValue = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入' + TypeMap[this.curBox.kick_type]));
        } else if (value && this.curBox.kick_type == 1 && !regRule['mobile'].test(value)) {
          return callback(new Error('请输入正确的手机号'));
        } else if (value && this.curBox.kick_type == 2 && !regRule['email'].test(value)) {
          return callback(new Error('请输入正确的邮箱'));
        } else {
          callback();
        }
      };
      return {
        TypeMap,
        selectIds: [], // 批量删除列表
        total: 1, // 列表总数
        searchParams: {
          searchName: '', // 搜索商品名称
          // 分页数据
          page_size: 10,
          page: 1
        },
        tableData: [], // 列表数据
        curBox: {
          id: '',
          kick_type: 1,
          kick_value: ''
        },
        dialogVisible: false,
        rules: {
          kick_type: [{ required: true, message: '请选择标识类型', trigger: 'blur' }],
          kick_value: [{ required: true, validator: validValue, trigger: 'blur' }]
        }
      };
    },
    computed: {},
    mounted() {
      this.getList('clearPage');
    },
    methods: {
      getList(type) {
        if (type == 'clearPage') {
          this.searchParams.page = 1;
        }
        this.isSearch = this.searchParams.searchName ? true : false;
        const commonParams = {
          keyword: this.searchParams.searchName,
          pos: (this.searchParams.page - 1) * this.searchParams.page_size,
          limit: this.searchParams.page_size
        };

        this.$fetch('getKickedList', {
          ...commonParams
        }).then(res => {
          if (res.code == 200) {
            this.total = res.data.total;
            this.tableData = res.data.list;
          }
        });
      },
      onSelect(val) {
        console.log(val);
        if (val?.length) {
          this.selectIds = val.map(item => item.id);
        } else {
          this.selectIds = [];
        }
      },
      onDelete(row) {
        this.$vhConfirm('您确定要对该观众执行移出全局踢出操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          customClass: 'zdy-message-box',
          lockScroll: false,
          //
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            let params = {};
            if (row) {
              params.global_kicked_ids = row.id;
            } else {
              params.global_kicked_ids = this.selectIds.join(',');
            }
            this.$fetch('delKickeduser', params)
              .then(res => {
                if (res.code == 200) {
                  this.$vhMessage.success('移出成功');
                  this.getList('clearPage');
                  this.selectIds = [];
                } else {
                  this.$vhMessage.error('移出失败');
                }
              })
              .catch(() => {
                this.$vhMessage.error('移出失败');
              });
          })
          .catch(e => {
            // this.$vhMessage({
            //   message: `已取消删除`,
            //   showClose: true,
            //   type: 'info',
            //   customClass: 'zdy-info-box'
            // });
          });
      },
      currentChange(val) {
        this.searchParams.page = val;
        this.getList();
      },
      sizeChange(val) {
        this.searchParams.page = 1;
        this.searchParams.page_size = val;
        this.getList();
      },
      onEdit(row) {
        this.dialogVisible = true;
        this.curBox.id = row.id;
        this.curBox.kick_type = row.kick_type;
        this.curBox.kick_value = row.kick_value;
      },
      add() {
        this.curBox = { id: '', kick_type: 1, kick_value: '' };
        this.dialogVisible = true;
      },
      handleClose() {
        this.curBox = { id: '', kick_type: 1, kick_value: '' };
        this.dialogVisible = false;
      },
      handleOk() {
        this.$refs['kickOutForm'].validate(valid => {
          if (valid) {
            let params = {
              type: this.curBox.kick_type,
              value: this.curBox.kick_value
            };
            if (this.curBox.id) {
              params.id = this.curBox.id;
            }
            this.$fetch('addKickeduser', {
              ...params
            })
              .then(res => {
                if (res.code == 200) {
                  this.getList('clearPage');
                  this.dialogVisible = false;
                }
              })
              .catch(error => {
                this.$vhMessage.error(error.msg);
              });
          }
        });
      }
    },
    filters: {
      typeFilter(val) {
        return TypeMap[val];
      }
    },
    created() {},
    beforeDestroy() {}
  };
</script>

<style lang="less">
  .kickOut {
    min-height: 400px;
    .filterGroup {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .vh-dialog {
      .vh-select,
      .vh-input {
        width: 250px;
      }
    }
  }
</style>
